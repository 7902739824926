import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Route} from 'react-router-dom'

import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import {Clients} from './pages/clients/Clients';
import {Countries} from './pages/Countries';
import {CountryDirections} from './pages/CountryDirections';
import PrimeReact, {addLocale} from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {PrivateRoute} from "./components/PrivateRoute";
import {EventTypes} from "./pages/EventTypes";
import {Users} from "./pages/Users";
import {MailTemplates} from "./pages/MailTemplates";
import {UserService} from "./service/UserService";
import useWebSocket from "react-use-websocket";
import {EventsCalendar} from "./pages/EventsCalendar";
import {Dashboard} from "./components/Dashboard";
import {Incoterms} from "./pages/Incoterms";
import {CargoItems} from "./pages/CargoItems";
import {Ports} from "./pages/Ports";
import {ContainerTypes} from "./pages/ContainerTypes";
import {Status} from "./pages/Status";
import {TransportMode} from "./pages/TransportMode";
import {EventStatus} from "./pages/EventStatus";
import AuthService from "./service/AuthService";
import {ShippingLines} from "./pages/ShippingLines";
import {ManagerByEvents} from "./pages/reports/ManagerByEvents";
import {Bookings} from "./pages/bookings/Bookings";
import {Booking} from "./pages/bookings/Booking";
import {CurrencyService} from "./service/CurrencyService";
import {CostCreditList} from "./pages/invoices/Costs/CostCreditList";
import {IncomeDebitList} from "./pages/invoices/Incomes/IncomeDebitList";
import {CostCreditForm} from "./pages/invoices/Costs/CostCreditForm";
import {IncomeDebitForm} from "./pages/invoices/Incomes/IncomeDebitForm";
import './badPlat.css'
import {Places} from "./pages/Places";
import {useMountEffect} from "primereact/hooks";
import moment from "moment/moment";
import {Payments} from "./pages/invoices/Payments";
import {ConfirmDialog} from "primereact/confirmdialog";
import {ExportToNavision} from "./pages/invoices/ExportToNavision";
import {PurchasesList} from "./pages/invoices/Purchases/PurchasesList";
import {PurchaseForm} from "./pages/invoices/Purchases/PurchaseForm";
import {isNullOrUndef} from "chart.js/helpers";
import {PlanRealReport} from "./pages/reports/PlanRealReport";
import {CostsReport} from "./pages/reports/CostsReport";
import {BookingsOperationsReport} from "./pages/reports/BookingsOperationsReport";
import {WagesReport} from "./pages/reports/WagesReport";
import {ClientGroupsService} from "./service/ClientGroupsService";
import {Button} from "primereact/button";
import { MdCleaningServices } from "react-icons/md";
import {CgCalendarToday} from "react-icons/cg";

export const RTLContext = React.createContext();
export const todayCalendar = (date) => {
    let d = new Date();
    if (!isNullOrUndef(date)) {
        d = moment(date).toDate();
    }
    return new Date(d.setMonth(d.getMonth() - 1))
}

const getMonthMath = (d, clickNext) => {
    if(clickNext) {
        return d.getMonth() + 1
    } else {
        return d.getMonth() - 1
    }
}

export const onViewChange = (e, ref) => {
    let clickNext = e.originalEvent.target.classList.value.includes('next');
    let d = ref.current.props.selectionMode === 'range' ? e.value : ref.current.props.viewDate;
    ref.current.props.viewDate = new Date(d.setMonth(getMonthMath(d, clickNext)))
}

export const calendarFooterTemplate = (calendarRef, calendarInputRef) => {
    return <div className={'flex justify-content-between'}>
        <Button label={'Today'} icon={<CgCalendarToday />} onClick={(e) => {
            calendarRef.current.updateViewDate(e, todayCalendar())
        }}/>
        <Button label={'Clear'} icon={<MdCleaningServices />} onClick={(e) => {
            calendarInputRef.current.value = null;
            calendarRef.current.props.value = null;
            calendarRef.current.props.onChange(e, null);
            calendarRef.current.updateViewDate(e, todayCalendar())
        }}/>
    </div>
}

const App = () => {

    const [menu, setMenu] = useState([]);
    const [menuMode, setMenuMode] = useState('static');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [isRTL, setRTL] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState('light');
    const [topbarTheme, setTopbarTheme] = useState('blue');
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [searchActive, setSearchActive] = useState(false)
    const [event, setEvent] = useState(null);
    const [userData, setUserData] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState({code: 'EUR'});
    const [clientGroups, setClientGroups] = useState([]);
    let currentInlineMenuKey = useRef(null);
    const userService = new UserService();
    const currencyService = new CurrencyService();
    const clientGroupsService = new ClientGroupsService();
    PrimeReact.ripple = true;

    const updated = '2024-09-10-01:25';

    addLocale('lt', {
        firstDayOfWeek: 1,
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'Today',
        clear: 'Clear'
    });

    let celebrateDays = ['1-1', '2-16', '3-11', '5-1', '6-24', '7-6', '8-15', '11-1', '11-2', '12-24', '12-25', '12-26']
    let celebrateDates = [];

    let today = new Date();
    let year = today.getFullYear();
    let nextYear = today.getFullYear() + 1;
    let yearBefore = today.getFullYear() - 1;
    celebrateDays.forEach(d => {
        celebrateDates.push(moment(year + '-' + d).toDate())
        celebrateDates.push(moment(nextYear + '-' + d).toDate())
        celebrateDates.push(moment(yearBefore + '-' + d).toDate())
    })
    const invalidDates = celebrateDates

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const checkUserRole = (roles) => {
        let havePermission = false;
        if (userData !== null && userData.authorities.length > 0) {
            havePermission = userData.authorities.some(a => Array.isArray(roles) ? roles.some(r => r === a) : a === roles)
        }
        // else {
        //     havePermission = userService.getUserData().then(data => {
        //         setUserData(data);
        //         if (data !== null && data.authorities.length > 0) {
        //             return data.authorities.some(a => a === role);
        //         } else {
        //             return false;
        //         }
        //     });
        // }
        return havePermission
    }

    const initMenu = () => {
        let menuTmp = [
            {
                items: [
                    {label: 'Accounts Management', icon: 'pi pi-fw pi-id-card', to: '/clients', visible: checkUserRole(['ROLE_ADMIN', 'READ_CLIENTS'])},
                    {label: 'Bookings', icon: 'pi pi-fw pi-book', to: '/bookings', visible: checkUserRole(['ROLE_ADMIN', 'READ_BOOKINGS'])},
                    {
                        label: 'Invoices', icon: 'pi pi-file-edit', visible: checkUserRole(['ROLE_ADMIN', 'READ_INVOICES']), items: [
                            {label: 'Debit', to: '/invoices/debit', visible: checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_DEBIT'])},
                            {label: 'Credit', to: '/invoices/credit', visible: checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_CREDIT'])},
                            {label: 'Payments', to: '/invoices/payments', visible: checkUserRole(['ROLE_ADMIN', 'READ_PAYMENTS'])},
                            {label: 'Office', to: '/invoices/purchases', visible: checkUserRole(['ROLE_ADMIN', 'READ_PURCHASES'])},
                            {label: 'Export', to: '/invoices/export', visible: checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_EXPORT'])},
                        ]
                    },
                    {
                        label: 'Reports', icon: 'pi pi-chart-pie', visible: checkUserRole(['ROLE_ADMIN', 'READ_REPORTS']), items: [
                            {label: 'Bookings operations', to: '/reports/bookings-operations-report', visible: checkUserRole(['ROLE_ADMIN', 'READ_REPORT_BOOKINGS_OPERATIONS'])},
                            {label: 'Kaupimai', to: '/reports/costs', visible: checkUserRole(['ROLE_ADMIN', 'READ_REPORT_COSTS'])},
                            {label: 'Manager by Events', to: '/reports/manager-by-events', visible: checkUserRole(['ROLE_ADMIN', 'READ_REPORT_MANAGER_BY_EVENTS'])},
                            {label: 'Plan/Real', to: '/reports/plan-real', visible: checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL'])},
                            {label: 'DU report', to: '/reports/wages', visible: checkUserRole(['ROLE_ADMIN'])},
                        ]
                    },
                    {label: 'Users', icon: 'pi pi-fw pi-users', to: '/users', visible: checkUserRole(['ROLE_ADMIN'])},
                    {
                        label: 'Settings', icon: 'pi pi-fw pi-cog', visible: checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']), items: [
                            {label: 'Cargo Items', to: '/cargo-items'},
                            {label: 'Containers types', to: '/container-types'},
                            {label: 'Countries', to: '/countries'},
                            {label: 'Directions', to: '/directions'},
                            {label: 'Event statuses', to: '/event-statuses'},
                            {label: 'Event types', to: '/event-types'},
                            {label: 'Incoterms', to: '/incoterms'},
                            {label: 'Mail templates', to: '/mail-templates'},
                            {label: 'Places', to: '/places'},
                            {label: 'Ports', to: '/ports'},
                            {label: 'Shipping lines', to: '/shipping-lines'},
                            {label: 'Status', to: '/status'},
                            {label: 'Transport modes', to: '/transport-modes'},
                        ]
                    },
                ]
            }
        ];
        setMenu(menuTmp);
    }

    const getSocketUrl = useCallback(() => {
        let url = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}`;
        url += `://${window.location.hostname}`;
        url += `${window.location.port !== '80' && window.location.port !== '' ? ':8080' : ''}`;
        url += `/notify/`;
        return new Promise(resolve => {
            if (userData !== null) {
                resolve(url += userData.id + "?token=" + AuthService.getCurrentUser()["id_token"]);
            }
        });
    }, []);

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket
    } = useWebSocket(getSocketUrl, {
        onOpen: () => console.log('opened'),
        onClose: () => console.log('closed'),
        onError: () => console.log('error'),
        onMessage: (message) => {
            setEvent(true)
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => false,
    });

    const clientsTypes = [
        {title: 'Potential', id: 1},
        {title: 'Current', id: 2},
        {title: 'Unavailable', id: 3}
    ]

    useMountEffect(() => {
        userService.getUserData().then(data => {
            setUserData(data)
        })
        currencyService.getCurrencies().then(data => {
            setCurrencies(data)
            let currency = data.find(c => c.standard)
            setDefaultCurrency(currency)
        })
        clientGroupsService.getClientGroups().then(data => {
            setClientGroups(data)
        })
        checkForUpdate();
    })

    useEffect(() => {
        initMenu();
    }, [userData])

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu()
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true)
        }
    }, [menuMode])

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');

        if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
            appLogoLink.src = 'assets/layout/images/bp-logo2.png';
        } else {
            appLogoLink.src = 'assets/layout/images/bp-logo2.png';
        }
    }, [topbarTheme])

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop())
            setDesktopMenuActive((prevState) => !prevState);
        else
            setMobileMenuActive((prevState) => !prevState)

        event.preventDefault();

    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else {
            setActiveTopbarItem(event.item)
        }

        event.originalEvent.preventDefault();
    }

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    }

    const resetStorage = (type) => {
        switch (type) {
            case 'bookings':
                resetBookingsStorage(true)
                return
            case 'clients':
                resetClientsStorage(true)
                return
            case 'payments':
                resetPaymentsStorage(true)
                return
            case 'debit':
                resetDebitStorage(true)
                return
            case 'credit':
                resetCreditStorage(true)
                return
            case 'purchase':
                resetPurchasesStorage(true)
                return
            case 'Plan/Real':
                resetPlanRealStorage(true)
                return
            case 'costs':
                resetCostsStorage(true)
                return
            case 'bookingsOperations':
                resetBookingsOperations(true)
                return
            case 'wages':
                resetWagesStorage(true)
                return
            default:
                resetAllSettingsStorage()
                return;
        }
    }

    const checkForUpdate = () => {
        const getLastUpdate = JSON.parse(window.localStorage.getItem('last-update'));
        if(getLastUpdate !== updated){
            window.localStorage.setItem('last-update', JSON.stringify(updated))
            resetAllSettingsStorage()
        }
    }

    const resetAllSettingsStorage = () => {
        resetBookingsStorage(false);
        resetPaymentsStorage(false);
        resetClientsStorage(false);
        resetDebitStorage(false);
        resetCreditStorage(false);
        resetPurchasesStorage(false);
        resetWagesStorage(false);
        if(checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL'])) {
            resetPlanRealStorage(false);
        }
        if(checkUserRole(['ROLE_ADMIN', 'READ_REPORT_COSTS'])) {
            resetCostsStorage(false);
        }
        if(checkUserRole(['ROLE_ADMIN', 'READ_REPORT_BOOKINGS_OPERATIONS'])) {
            resetBookingsOperations(false);
        }
        window.location.reload(false);
    }

    const resetBookingsStorage = (reload) => {
        localStorage.removeItem('bookings-filter-data');
        localStorage.removeItem('bookings-datatable-filters');
        localStorage.removeItem('bookings-datatable-columns');
        localStorage.removeItem('bookings-filter-toggle');
        localStorage.removeItem('booking-dt-state-local');
        localStorage.removeItem('bookings-accordion');
        localStorage.removeItem('bookings-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetPlanRealStorage = (reload) => {
        localStorage.removeItem('plan-real-filter-data');
        localStorage.removeItem('plan-real-datatable-filters');
        localStorage.removeItem('plan-real-datatable-columns');
        localStorage.removeItem('plan-real-filter-toggle');
        localStorage.removeItem('plan-real-dt-state-local');
        localStorage.removeItem('plan-real-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetWagesStorage = (reload) => {
        localStorage.removeItem('wages-filter-data');
        localStorage.removeItem('wages-real-datatable-filters');
        localStorage.removeItem('wages-datatable-columns');
        localStorage.removeItem('wages-filter-toggle');
        localStorage.removeItem('wages-dt-state-local');
        localStorage.removeItem('wages-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetCostsStorage = (reload) => {
        localStorage.removeItem('costs-report-filter-data');
        localStorage.removeItem('costs-report-datatable-filters');
        localStorage.removeItem('costs-report-datatable-columns');
        localStorage.removeItem('costs-report-filter-toggle');
        localStorage.removeItem('costs-report-dt-state-local');
        localStorage.removeItem('costs-report-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetBookingsOperations = (reload) => {
        localStorage.removeItem('bookings-operations-report-filter-data');
        localStorage.removeItem('bookings-operations-report-datatable-filters');
        localStorage.removeItem('bookings-operations-report-datatable-columns');
        localStorage.removeItem('bookings-operations-report-filter-toggle');
        localStorage.removeItem('bookings-operations-report-dt-state-local');
        localStorage.removeItem('bookings-operations-report-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetPaymentsStorage = (reload) => {
        localStorage.removeItem('payments-filter-data');
        localStorage.removeItem('payments-datatable-filters');
        localStorage.removeItem('payments-datatable-columns');
        localStorage.removeItem('payments-filter-toggle');
        localStorage.removeItem('payments-dt-state-local');
        localStorage.removeItem('payments-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetClientsStorage = (reload) => {
        localStorage.removeItem('clients-filter-data');
        localStorage.removeItem('clients-datatable-filters');
        localStorage.removeItem('clients-datatable-columns');
        localStorage.removeItem('clients-filter-toggle');
        localStorage.removeItem('clients-dt-state-local');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetDebitStorage = (reload) => {
        localStorage.removeItem('debit-filter-data');
        localStorage.removeItem('debit-datatable-filters');
        localStorage.removeItem('debit-datatable-columns');
        localStorage.removeItem('debit-filter-toggle');
        localStorage.removeItem('debit-dt-state-local');
        localStorage.removeItem('debit-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetCreditStorage = (reload) => {
        localStorage.removeItem('credit-filter-data');
        localStorage.removeItem('credit-datatable-filters');
        localStorage.removeItem('credit-datatable-columns');
        localStorage.removeItem('credit-filter-toggle');
        localStorage.removeItem('credit-dt-state-local');
        localStorage.removeItem('credit-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const resetPurchasesStorage = (reload) => {
        localStorage.removeItem('purchases-filter-data');
        localStorage.removeItem('purchases-datatable-filters');
        localStorage.removeItem('purchases-filter-toggle');
        localStorage.removeItem('purchases-dt-state-local');
        localStorage.removeItem('purchases-sort-data');
        if(reload) {
            window.location.reload(false);
        }
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false)
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    }

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState)
    }

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = {...inlineMenuActive};
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    }

    const hideOverlayMenu = () => {
        setMobileMenuActive(false)
        setDesktopMenuActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': isRTL
    });

    const getClientGroupId = (type) => {
        if(!isNullOrUndef(clientGroups) && !isNullOrUndef(type)) {
            let groupId;
            clientGroups.forEach(cg => {
                if(cg.title.toLowerCase().trim().includes(type)){
                    groupId = cg.id
                }
            });
            return groupId;
        }
    }

    return (
        <RTLContext.Provider value={isRTL}>
            <ConfirmDialog/>
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <AppTopbar horizontal={isHorizontal()}
                           resetStorage={resetStorage}
                           checkUserRole={checkUserRole}
                           event={event} userData={userData}
                           activeTopbarItem={activeTopbarItem}
                           onMenuButtonClick={onMenuButtonClick}
                           onTopbarItemClick={onTopbarItemClick}
                           onRightMenuButtonClick={onRightMenuButtonClick}
                           onMobileTopbarButtonClick={onMobileTopbarButtonClick} mobileTopbarActive={mobileTopbarActive}
                           searchActive={searchActive} onSearch={onSearch}
                           currencies={currencies}/>

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                                 onRootMenuItemClick={onRootMenuItemClick}
                                 menuMode={menuMode} active={menuActive}/>
                    </div>
                </div>

                <div className="layout-main" style={{background:'#ecf0f5'}}>

                    {!isNullOrUndef(userData) ?
                        <div className="layout-content">
                            {checkUserRole(['ROLE_ADMIN', 'READ_CLIENTS']) ?
                                <Route path="/clients" exact={true}
                                       render={() => (
                                           <Clients clientsTypes={clientsTypes} checkUserRole={checkUserRole}
                                                    userData={userData} clientGroups={clientGroups}
                                                    getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_BOOKINGS']) ?
                                <Route path="/bookings" exact={true}
                                       render={() => (
                                           <Bookings clientsTypes={clientsTypes} userData={userData}
                                                     clientGroups={clientGroups} getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_BOOKINGS']) ?
                                <Route path="/bookings/add" exact={true} render={() => (
                                    <Booking clientsTypes={clientsTypes} userData={userData} currencies={currencies}
                                             clientGroups={clientGroups} getClientGroupId={getClientGroupId}
                                             defaultCurrency={defaultCurrency} checkUserRole={checkUserRole}/>)}/>
                                : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_BOOKINGS']) ?
                                <Route exact={true} path="/bookings/view/:paramBookingId/:paramTab?" render={() => (
                                    <Booking clientsTypes={clientsTypes} userData={userData} currencies={currencies}
                                             clientGroups={clientGroups} getClientGroupId={getClientGroupId}
                                             defaultCurrency={defaultCurrency} checkUserRole={checkUserRole}/>)}/>
                                : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_DEBIT']) ?
                                <Route path="/invoices/debit" exact={true}
                                       render={() => (<IncomeDebitList userData={userData}
                                                                       checkUserRole={checkUserRole}
                                                                       clientGroups={clientGroups}
                                                                       getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_CREDIT']) ?
                                <Route path="/invoices/credit" exact={true}
                                       render={() => (<CostCreditList userData={userData}
                                                                      clientGroups={clientGroups}
                                                                      getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_CREDIT']) ?
                                <Route exact={true}
                                       path="/invoices/credit/view/:paramInvoiceId/:paramClientId?/:paramBookingId?/:paramTab?"
                                       render={() => (
                                           <CostCreditForm clientsTypes={clientsTypes} userData={userData}
                                                           checkUserRole={checkUserRole} clientGroups={clientGroups}
                                                           getClientGroupId={getClientGroupId}
                                                           currencies={currencies} defaultCurrency={defaultCurrency}/>
                                       )}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_DEBIT']) ?
                                <Route exact={true}
                                       path="/invoices/debit/view/:paramInvoiceId/:paramBookingId?/:paramTab?"
                                       render={() => (
                                           <IncomeDebitForm clientsTypes={clientsTypes} userData={userData}
                                                            currencies={currencies} checkUserRole={checkUserRole}
                                                            celebrateDays={celebrateDays} invalidDates={invalidDates}
                                                            defaultCurrency={defaultCurrency} clientGroups={clientGroups}
                                                            getClientGroupId={getClientGroupId}/>
                                       )}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_PAYMENTS']) ?
                                <Route path="/invoices/payments" exact={true}
                                       render={() => (
                                           <Payments userData={userData} checkUserRole={checkUserRole}
                                                     clientGroups={clientGroups}
                                                     getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_INVOICES_EXPORT']) ?
                                <Route path="/invoices/export" exact={true}
                                       render={() => (<ExportToNavision userData={userData} clientGroups={clientGroups}
                                                                        getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_PURCHASES']) ?
                                <Route path="/invoices/purchases" exact={true}
                                       render={() => (<PurchasesList clientsTypes={clientsTypes} userData={userData}
                                                                     currencies={currencies} clientGroups={clientGroups}
                                                                     getClientGroupId={getClientGroupId}
                                                                     defaultCurrency={defaultCurrency}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_PURCHASES']) ?
                                <Route path="/invoices/purchases/view/:paramInvoiceId" exact={true}
                                       render={() => (<PurchaseForm clientsTypes={clientsTypes} userData={userData}
                                                                    currencies={currencies} clientGroups={clientGroups}
                                                                    getClientGroupId={getClientGroupId}
                                                                    defaultCurrency={defaultCurrency}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_REPORT_MANAGER_BY_EVENTS']) ?
                                <Route exact={true} path="/reports/manager-by-events"
                                       render={() => (
                                           <ManagerByEvents clientsTypes={clientsTypes} userData={userData}/>)}/> : ''}

                            {checkUserRole('ROLE_ADMIN') ?
                                <Route path="/users" exact={true} render={() => (<Users userData={userData}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL']) ?
                                <Route path="/reports/plan-real" exact={true}
                                       render={() => (<PlanRealReport userData={userData}
                                                                      clientGroups={clientGroups}
                                                                      getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL']) ?
                                <Route path="/reports/wages" exact={true}
                                       render={() => (<WagesReport userData={userData}
                                                                   clientGroups={clientGroups}
                                                                   getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_REPORT_COSTS']) ?
                                <Route path="/reports/costs" exact={true}
                                       render={() => (<CostsReport userData={userData}
                                                                   clientGroups={clientGroups}
                                                                   getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_REPORT_BOOKINGS_OPERATIONS']) ?
                                <Route path="/reports/bookings-operations-report" exact={true}
                                       render={() => (<BookingsOperationsReport userData={userData}
                                                                                clientGroups={clientGroups}
                                                                                getClientGroupId={getClientGroupId}/>)}/> : ''}

                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/countries" component={Countries}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/incoterms" component={Incoterms}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/cargo-items" component={CargoItems}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/ports" component={Ports}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/places" component={Places}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/status" component={Status}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/container-types" component={ContainerTypes}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/directions" component={CountryDirections}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/transport-modes" component={TransportMode}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/event-types" component={EventTypes}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/event-statuses" component={EventStatus}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/mail-templates" component={MailTemplates}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <Route exact={true} path="/my-events"
                                       render={() => (<EventsCalendar userData={userData}/>)}/> : ''}
                            {checkUserRole(['ROLE_ADMIN', 'READ_SETTINGS']) ?
                                <PrivateRoute exact={true} path="/shipping-lines" component={ShippingLines}/> : ''}

                            <PrivateRoute exact={true} path="/dashboard" component={Dashboard}/>
                            {/*<Route exact path="/">*/}
                            {/*    <Redirect to="/dashboard" />*/}
                            {/*</Route>*/}
                            {/*<Route path="/pages/crud" component={Crud} />*/}
                        </div> : ''}

                </div>

                {mobileMenuActive && <div className="layout-mask modal-in"/>}
            </div>
        </RTLContext.Provider>
    );

}

export default App;
